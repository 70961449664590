.location-container {
    opacity: 0;
    color: white;
    font-size: 14px;
    -webkit-transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -ms-transition: opacity 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    text-align: center;
}

.country {
    width: 90px !important;
    text-align: center;

    span {
        color: white;
        font-size: 14px;
        /*-webkit-transition: opacity 0.25s ease-in-out;
		-moz-transition: opacity 0.25s ease-in-out;
		-ms-transition: opacity 0.25s ease-in-out;
		-o-transition: opacity 0.25s ease-in-out;
		transition: opacity 0.25s ease-in-out;*/
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        text-align: center;
    }

    .flag {
        width: 90px !important;
    }

    img {
        width: 50px !important;
    }
}

#liveMapContainer {
  height: 100%;
  width: 100%;

  .circle {
    width: 7px;
    height: 7px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    z-index: 50;
    background-color: #158CFB;
    cursor: pointer;
    cursor: -o-pointer;
    cursor: -moz-pointer;
    cursor: -webkit-pointer;

    &.big
    {
        border-width: 1px;
    }
  }
}
