$font-family-sans-serif: "Poppins";

$body-color: #d7d5d9 !default;
$font-size-base: 0.9rem !default;
$root-font-size: 0.9rem !default;

$enable-rounded: true;
$card-border-enabled: true;
$input-btn-padding-y: 0.5rem;
$list-group-bg: transparent;
$badge-color: $body-color;
$form-switch-height: 1.2rem;
$form-switch-width: 2.25rem;
$card-py: 1rem;
$card-px: 1.25rem;
$enable-cssgrid: true;

@import url("https://fonts.googleapis.com/css2?family=Catamaran&family=Didact+Gothic&family=Jost&family=Lato&family=Montserrat&family=Nunito&family=Open+Sans&family=Overpass&family=Raleway&family=Roboto&family=Rubik&family=Ubuntu&family=Hind&family=Poppins&display=swap");

//@import "custom";
//@import "../../../node_modules/bootstrap/scss/bootstrap";
//@import "bootswatch";
@import "metronic/style.dark";

.card {
  .card-header {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 1.15;
    justify-content: normal;
    @extend .bg-light;

    &.with-toolbar {
      align-items: center !important;
      justify-content: space-between;

      & .buttons {
        float: end !important;
      }
    }
  }
}

th {
  font-weight: 600 !important;
  color: $body-color;
}

.aside {
  font-size: 1rem;

  /*.aside-logo {
        height: 50px;
        padding: 0px 10px 0 4px;
    }*/
}

.badge {
  &.badge-light-primary {
    color: $body-color;
    background-color: $primary;
  }
}

@import "trucky-icons";
@import "mixins";
@import "trucky";
@import "color-picker";
@import "resize";
@import "owlCarousel2";
@import "animations";
@import "contextMenu";
@import "calendar";

$border-color: $secondary;
$calendar-border: $secondary;
$cell-border: $secondary;
