@font-face {
    font-family: 'Trucky';
    src: url('fonts/icomoon.ttf') format('truetype'),
        /* Safari, Android, iOS */
}

.ti {
    font-family: 'Trucky';
    font-style: normal;
    margin-right: 10px;
    display: inline-block;
    height: 1em;

    &.adjust {
        margin-top: -3px;
    }

    &.ti-logo:before {
        content: "\e903";
    }

    &.ti-high-beams {

        &:before {
            content: "\e904";
        }

        &.enabled {
            color: $blue;
        }
    }

    &.ti-parking-lights {
        &:before {
            content: "\e90a";
        }

        &.enabled {
            color: $green;
        }
    }

    &.ti-speed-limiter:before {
        content: "\e91a";

        &.enabled {
            color: $green;
        }
    }

    &.ti-low-beams {
        &:before {
            content: "\e907";
        }

        &.enabled {
            color: $green;
        }
    }

    &.ti-odometer:before {
        content: "\e919";
    }

    &.ti-rpm:before {
        content: "\e901";
    }

    &.ti-parking-brake {
        &:before {
            content: "\e902";
        }

        &.enabled {
            color: $red;
        }
    }

    &.ti-turn-indicator-left:before {
        content: "\e909";
    }

    &.ti-turn-indicator-right:before {
        content: "\e908";
    }

    &.ti-hazard {
        &:before {
            content: "\e90b";
        }

        &.enabled {
            color: $orange;
        }
    }

    &.ti-beacon {
        &:before {
            content: "\e90e";
        }

        &.enabled {
            color: $orange;
        }
    }

    &.ti-motor-brake {
        &:before {
            content: "\e90d";
        }

        &.enabled {
            color: $red;
        }
    }

    &.ti-differential-lock {
        &:before {
            content: "\e90c";
        }

        &.enabled {
            color: $yellow;
        }
    }

    &.ti-retarder {
        &:before {
            content: "\e90f";
        }

        &.enabled {
            color: $yellow;
        }
    }

    &.ti-wheel-lift {
        &:before {
            content: "\e915";
        }

        &.enabled {
            color: $yellow;
        }
    }

    &.ti-trailer {
        &:before {
            content: "\e911";
        }
    }

    &.ti-trailer-damage {
        &:before {
            content: "\e912";
        }
    }

    &.ti-brake-air-pressure {
        &:before {
            content: "\e913";
        }

        &.warning {
            color: $yellow;
        }

        &.emergency {
            color: $red;
        }
    }

    &.ti-brake {
        &:before {
            content: "\e914";
        }

        &.warning {
            color: $yellow;
        }

        &.emergency {
            color: $red;
        }
    }

    &.ti-truck-ATS {
        &:before {
            content: "\e917";
        }
    }

    &.ti-truck-ETS2 {
        &:before {
            content: "\e916";
        }
    }

    &.ti-tb {
        &:before {
            content: "\e91c";
        }
    }
}