* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
}

a {
  cursor: pointer;
  color: white;

  &:hover,
  &:link,
  &:visited {
    color: white;
    cursor: pointer;
  }

  &:hover {
    color: darken($body-color, 10%) !important;
    text-decoration: underline !important;
  }
}

a:not([href]):not([class]) {
  color: $primary;

  &:hover {
    color: darken($body-color, 10%) !important;
    text-decoration: underline !important;
  }
}

::selection {
  background: rgba(255, 255, 255, 0.2);
}

img {
  max-width: 100%;
}

html {
  height: 100%;
  cursor: default;
  padding: 0;
  margin: 0;

  //border: 1px solid $gray-900;

  /*&.nofullh {
    height: auto !important;
  }*/
}

/*.table-dark {

  &.table-sm {
    padding: .5rem;
  }

  thead {
    th {
      font-weight: 900;
    }
  }

  td {
    vertical-align: middle;
  }
}*/

.scroll {
  overflow-y: auto;
  @include scrollbar();
}

.scroll-y {
  overflow-y: auto;
}

.scrollbar {
  @include scrollbar();
}

.no-scrollbars {
  overflow: hidden;
}

body {
  height: 100%;
  cursor: default;
  padding: 0;
  margin: 0;
  background: transparent;
  overflow-x: hidden;

  &.transparent-80 {
    background: rgba(60, 60, 60, 0.8);
  }

  &.transparent-75 {
    background: rgba(60, 60, 60, 0.75);
  }

  &.transparent-90 {
    background: rgba(60, 60, 60, 0.9);
  }

  &.transparent-50 {
    background: rgba(60, 60, 60, 0.5);
  }

  &.full-transparent {
    background: transparent;
  }

  &.options {
    .nav-link {
      font-size: 0.8rem;
    }

    #optionsTabContent {
      max-width: 100%;
      overflow-x: hidden;
    }
  }

  &[data-language="ru"],
  &[data-language="bn-BD"],
  &[data-language="el"],
  &[data-language="he"],
  &[data-language="th"] {
    font-family: Roboto;

    .tooltip {
      font-family: Roboto !important;
    }
  }

  /*&.nofullh {
    height: auto !important;
  }*/
}

.progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  width: 1%;
  height: 20px;
  background: red;
  transition: width 15s;
  -webkit-transition: width 15s;

  /* Safari 3.1 to 6.0 */
}

.strip {
  background: rgba(165, 23, 69, 0.5);

  .icon {
    margin-right: 20px;
  }
}

.opaque {
  background: #151521;
}

.window-controls-group {
  display: flex;

  button {
    --size: 30px;
  }
}

.window-control {
  color: #a8a8a8;
  background-color: transparent;

  &:hover {
    color: #dedede;
    background-color: $primary;
  }
}

.window-control-close:hover {
  background-color: $primary;
}

/*.content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    border: 1px solid #333333;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    pointer-events: none;
  }
}
*/

header {
  flex-shrink: 0;
  display: flex;

  > *:only-of-type,
  * + * {
    margin-left: auto;
  }
}

main {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.noscroll-horizontal {
  overflow-x: hidden;
}

.noscroll-vertical {
  overflow-y: hidden;
}

main.index {
  height: 40px;
}

html.maximized {
  padding: 0;

  body {
    box-shadow: none;
  }

  .resize {
    transform: none;
  }
}

/* bootstrap overrides */

header {
  background-color: $primary;

  a {
    color: $body-color !important;
  }
}

.window-title {
  /*width: 100%;*/
  padding-left: 10px;
}

.loader {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.tinyicon {
  /*margin-top: 3px;*/
  margin-left: 5px;
}

.window-title .tinyicon {
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 7px;
}

.bar-buttons {
  margin-top: 5px;
  width: 300px;
}

.report-button {
  width: 45px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.report-icon {
  width: 50px;
  margin-right: 15px;
  float: left;
}

.alert-distance,
.alert-type {
  font-size: 16px;
}

.alert-author {
  font-size: 14px;
  margin-left: 10px;
}

.welcome .report-button {
  margin-bottom: 10px;
  margin-left: 0px;
}

.trucky-alert {
  padding-top: 20px;
  padding-bottom: 15px;
}

.index-col {
  margin-top: 5px;
}

.game-info-icon {
  &.disabled {
    color: grey;
  }

  &.enabled {
    color: green;
  }
}

.cruise-control-speed {
  margin-right: 20px;
}

.game-info-icon.cruise-control {
  margin-right: 4px;
}

#road-sign {
  display: block;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border: 5px solid #ee0000;
  border-radius: 100px;

  span {
    position: relative;
    margin-top: -2px;
    margin-left: 2px;
    font-size: 18px;
    font-weight: bold;
    color: black;

    &.three-digits {
      position: absolute;
      font-size: 13px;
      margin-top: 2px;
      margin-left: 1px;
      font-weight: bold;
      color: black;
    }
  }

  &.ats {
    display: block;
    text-align: center;
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;

    span {
      position: relative;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      color: black;
      margin-top: 3px;
    }
  }
}

.notingame-container {
  background: rgba(255, 255, 255, 0.9);
  color: $gray-900;

  a {
    color: $gray-900;
  }
}

.notingame-container i {
  color: $gray-900;
}

.notingame-footer {
  color: $gray-900;
  background: rgba(255, 255, 255, 0.9);
  padding-left: 5px;
  padding-right: 5px;

  a {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $gray-900;
    }
  }
}

.svg-inline--fa {
  margin-right: 10px;
}

.btn-icon {
  .svg-inline--fa {
    margin-right: 0;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.index-vertical {
  margin: 5px;
}

.gameInfo-vertical {
  margin: 5px;

  #road-sign {
    margin-left: auto;
    margin-right: auto;

    span {
      &.three-digits {
        margin-left: -8px;
        margin-top: 4px;
      }
    }

    &.ats {
      span {
        &.three-digits {
          margin-left: 1px;
          margin-top: 5px;
        }
      }
    }
  }

  .license-plate {
    height: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .cruise-control {
    margin-left: 7px;
    margin-right: 5px;
  }
}

.gameInfo-section-title {
  font-weight: bold;
  border-bottom: 1px solid grey;
  margin-bottom: 5px;
}

/* cards */

.gamereport {
  .card-header {
    color: black;
  }

  .card-text {
    color: black;
    font-size: 28px;
    font-weight: bold;
  }

  .playingtime {
    font-size: 28px;
    font-weight: bold;
  }

  .separator {
    border-bottom: 1px solid grey;
  }
}

.custom-logo {
  max-height: 25px;
  /*margin-right: 10px;*/
  margin-top: auto;
  margin-bottom: auto;
}

.checkpoint-sign {
  width: 30px;
  padding: 3px;

  &.selected {
    border: 1px solid theme-color("primary");
  }
}

.convoy-permissions {
  height: 130px;
  overflow-y: scroll;
  @include scrollbar();

  .list-group-item {
    padding-left: 0;
  }
}

.checkpoints {
  .list-group-item {
    padding-left: 0;
  }
}

.convoy-chat {
  max-height: 180px;
  overflow-y: auto;
  @include scrollbar();
}

.header-hidden {
  height: 15px;

  > * {
    display: none;
  }
}

.trailers-list {
  margin-bottom: 0;

  li {
    display: inline;
    margin-right: 10px;

    .fa-trailer {
      margin-right: 5px;
    }
  }
}

.inline-game-info-icon {
  margin-right: 0px;
  margin-left: 10px;

  &.enabled {
    color: green;
    margin-right: 5px;
  }
}

.custom-logo-nomargin {
  margin: auto;
}

.remove-ads-container {
  background: black;
  text-align: center;

  a,
  a:active,
  a:hover,
  a:visited {
    color: $primary;
    font-weight: bold;
  }
}

.ad-container-160 {
  width: 160px;
}

.gamereport-cards-container {
  width: 740px;
}

.stat {
  .stat-completed {
    background: #2a3134;
    padding: 17px;
  }

  .value-container {
    height: 42px;
    margin-bottom: 2px;
    color: #fe8200;

    span.value {
      font-size: 34px;
    }

    span.unit {
      font-size: 20px;
    }
  }

  .name {
    height: 32px;
    color: #909393;
    text-transform: uppercase;
  }
}

.stat-left-col {
  width: 400px;
  margin-right: 15px;
}

.stat-right-col {
  width: 400px;
}

.launch-games-buttons {
  /*text-align: center;*/

  .game-logo-container {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .game-logo {
    position: relative;
    display: inline-block;

    svg {
      display: none;
    }

    &:hover {
      img {
        opacity: 0.5;
      }

      svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        font-size: 100px;
        color: $primary;
        text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
        background: $gray-300;
        border-radius: 55px;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    display: inline-block;
    cursor: pointer;

    span {
      color: $gray-200;
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}

.medias {
  .media-container {
    position: relative;

    img {
      opacity: 0.5;
    }

    .media-icon {
      opacity: 0;
      position: absolute;
      top: 41%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      opacity: 1;
      color: white;
      font-size: 30px;
    }
  }
}

.changelog {
  a,
  a:visited,
  a:active,
  a:hover,
  a:not([href]):not([class]) {
    text-decoration: underline;
  }
}

.statsvalue {
  color: #fe8200 !important;
  text-align: right;
}

.totalPoints {
  color: #fe8200 !important;
  font-weight: bold;
  font-size: 18px;
  text-align: right;
}

tr.rewards-fixedHeightRow {
  min-height: 46px;
  max-height: 46px;
  height: 46px;
}

.rewards-container {
  max-height: 375px;
  overflow-y: auto;
  @include scrollbar();
}

.achievements-list {
  height: 100%;
  @include scrollbar();
}

.achievement-reference,
.level_max_points {
  color: $success;
}

.achievement-goal,
.level_min_points {
  color: #fe8200;
}

.progress {
  height: 10px;

  .progress-bar {
    height: 10px;
    position: relative;
  }
}

.level {
  font-size: 20px;
}

/*.tab-content
{
  display: flex;

  > .tab-pane {
    display: block;
    visibility: hidden;
    margin-right: -100%;
    width: 100%;

    &.active
    {
      visibility: visible;
    }
  }
}*/

.navbar {
  padding: 0;

  .navbar-brand {
    span {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

.download-app-badge {
  img {
    width: 160px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.achievements-scroll {
  overflow-y: auto;
  @include scrollbar();
}

.socials {
  color: rgba(255, 255, 255, 0.5);

  svg {
    cursor: pointer;
  }
}

.scroll-calc-h100 {
  height: calc(100%);
  overflow-y: scroll;
  @include scrollbar();
}

.user-info {
  span.points {
    font-size: 20px;
    font-weight: bold;
    color: #fe8200;
  }
}

.desktop-navbar {
  /*font-size: 1rem;*/
  background-color: $primary;
  /* box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75); */
  /* z-index: 10000; */
  background: linear-gradient(
    90deg,
    rgba(120, 17, 50, 1) 33%,
    rgba(165, 23, 69, 1) 65%
  );
  /*border-bottom: 1px solid $primary;*/

  .menu-item {
    display: inline;
    padding: 0.5rem;
    color: $body-color;

    &.active,
    &:hover {
      color: $body-color;
    }
  }

  svg {
    color: #bfbfbf;

    &:hover {
      color: $body-color;
    }
  }

  .toolbar {
    float: right;
    padding-top: 1px;
    padding-bottom: 2px;

    svg {
      margin-top: 7px;
    }
  }

  ul {
    display: inline;
    padding: 0;
    list-style: none;

    li {
      display: inline;
    }

    li.toolbar-separator {
      margin-left: 2rem;
    }

    li.toolbar-separator-right {
      margin-right: 2rem;
    }
  }

  a:hover {
    cursor: pointer;
  }

  .logo {
    width: 140px;
    letter-spacing: 9px;
    font-size: 20px;
    text-align: center;

    img {
      margin-right: 10px;
    }
  }

  .overlay-status {
    padding: 10px;
    background-color: darken($primary, 10%);
    font-size: 14px;
    padding: 7px 10px 1px;
  }
}

.profile-avatar {
  width: 100px;
}

.card {
  &.card-small-margin-right {
    margin-right: 0.25rem !important;
  }

  &.card-small-margin-left {
    margin-left: 0px;
  }

  &.nopadding {
    padding: 0px;
  }

  &.card-mb-0 {
    margin-bottom: 0 !important;
  }

  &.card-mt-0 {
    margin-top: 0 !important;
  }

  &.card-mt-1 {
    margin-top: 0.25rem !important;
  }

  &.card-dark {
    background-color: darken(#343a40, 8%);
  }
}

.col,
[class*="col-"] {
  &.nopaddingleft {
    padding-left: 0px;
  }

  &.nopaddingright {
    padding-right: 0px;
  }
}

.special-transport {
  background-image: linear-gradient(
    45deg,
    #ffe500 25%,
    #000001 25%,
    #000001 50%,
    #ffe500 50%,
    #ffe500 75%,
    #000001 75%,
    #000001 100%
  );
  background-size: 11.31px 11.31px;
  width: 180px;

  span {
    margin-left: 15px;
    margin-right: 15px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    background-color: #ffe500;
    color: black;
  }
}

.desktop-footer {
  background: lighten($gray-900, 5%);
  padding-left: 5px;
  padding-right: 5px;
}

.windowmode-selected {
  border: 1px solid $success;
}

.hotkey {
  display: inline-block;
  width: 90px;
  font-weight: bold;
}

.progress-bar-step {
  background-color: $primary;

  &:not(.active) {
    background-color: #32383e;

    .step-number {
      background-color: darken(#32383e, 10%);
    }
  }

  &.active {
    .step-number {
      background-color: darken($primary, 10%);
    }
  }

  .step-number {
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: bold;
  }
}

.hotkeys-hint {
  padding-left: 10px;
  padding-right: 10px;
  background-color: darken($primary, 10%);
  font-size: 0.6rem;
  background-color: black;
  opacity: 0.3;
  padding-top: 5px;
}

@keyframes verticalNavBarWidthChange {
  0% {
    width: 32px;
  }

  100% {
    width: 160px;
  }
}

@keyframes verticalNavBarWidthChangeReverse {
  0% {
    width: 160px;
  }

  100% {
    width: 32px;
  }
}

.vertical-nav-bar {
  background-color: darken($primary, 10%);
  width: 160px;
  display: flex;
  flex-direction: column;

  .menu-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 40px;
    padding: 5px 10px 5px 10px;
    display: block;
    color: $body-color;
    position: relative;

    &:active,
    &:visited {
      text-decoration: none;
      color: $body-color;
    }

    &.active,
    &:hover {
      background-color: darken($primary, 5%);
      font-weight: bold;
      color: $body-color;
      text-decoration: none;
    }
  }

  .aligned-bottom {
    display: flex;
    flex: 1;
    align-items: flex-end;

    .footer {
      margin-bottom: 35px;
      text-align: center;
      width: 100%;
    }
  }

  &.collapsed {
    width: 37px;

    span {
      display: none;
      text-overflow: initial;
    }

    .footer {
      display: none;
    }

    &.open {
      .menu-item {
        span {
          display: inline;
        }
      }

      .footer {
        display: block;
      }
    }
  }
}

.points {
  color: #fe8200;
  font-weight: bold;
}

.leaderboards {
  .rdt_TableHeader {
    display: none;
  }

  div[role="row"] {
    min-height: auto;
  }

  div.rdt_TableHeadRow {
    padding: 0.3rem;
  }

  div[role="gridcell"] {
    padding: 0.3rem;
  }

  .profile-image-small {
    width: 40px;
  }

  .ranking,
  .points,
  .level {
    font-size: $font-size-base * 1.1;
  }

  .media {
    h5 {
      font-size: $font-size-base;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

/*.form-control,
.form-select {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid $body-color;
}*/

.card {
  margin: 0.25rem;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-top-9 {
  margin-top: 9px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

/*.separator {
  width: 20px;
}*/

.col.col-12-percent {
  flex: 0 0 12% !important;
  max-width: 12% !important;
}

.col.col-10-percent {
  flex: 0 0 10% !important;
  max-width: 10% !important;
}

.col.col-8-percent {
  flex: 0 0 8% !important;
  max-width: 8% !important;
}

.col.col-5-percent {
  flex: 0 0 5% !important;
  max-width: 5% !important;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-border-radius {
  border-radius: 0 !important;
}

.react-select__menu {
  background-color: #393f44;
  z-index: 999999;
}

.react-select__menu-list {
  @include scrollbar();
  background-color: #393f44;
  z-index: 999999;
}

/*h5,
h6 {
  padding-bottom: 2px;
  border-bottom: 1px solid $gray-700;
}*/

h5.modal-title {
  padding-bottom: 0 !important;
  border-bottom: none !important;
}

.premium-text {
  color: $premium;
  font-size: $font-size-sm;
}

.premium-color {
  color: $premium !important;
}

.btn-patreon-button {
  @include button-variant($patreon, darken($patreon, 10%), $body-color);
}

.btn-premium {
  @include button-variant($premium, darken($premium, 10%), $black);
}

.enabled {
  color: $green;
}

.scrollbar-padding {
  padding-right: 15px;
}

.vtrpc {
  background-color: #6c82cf;
  color: #fff;
  padding: 10px;

  .text {
    padding-top: 15px;
  }

  .largeimage {
    position: relative;
  }

  .smallimage {
    position: absolute;
    top: 73px;
    right: -10px;

    .image {
      border: 3px solid #6c82cf;
    }
  }
}

.react-resizable-handle {
  background-color: $gray-800;
  width: 10px;
  height: 10px;
}

.sme {
  .widget {
    .card {
      box-shadow: 4px 4px 12px 1px rgba(0, 0, 0, 0.54);
      height: 100%;
      overflow-x: hidden;
    }

    .card-header {
      background-color: $primary;
      padding-top: 2px;
      padding-left: 5px;
      padding-right: 2px;
      height: 22px;
      display: block;

      a {
        display: none;
      }

      &:hover {
        a {
          display: inline;
        }
      }
    }

    .card-body {
      overflow-x: hidden;
      padding: 10px !important;
      @include scrollbar();

      .list-group-item {
        padding: 0.5rem 0.5rem !important;
      }
    }

    .tutorial {
      position: absolute;
      width: 400px;
      height: 300px;
    }
  }

  .fixed-banner {
    z-index: 999999;
    right: 22px;
  }
}

.react-draggable-dragging {
  //opacity: 0.5;
  //background-color: transparent($primary, 0.5);
}

.widget-options-container {
  &.popover {
    z-index: 99999 !important;
  }

  .popover-inner {
    .popover-body {
    }
  }
}

#routeStopsMapContainer {
  min-height: 400px;
}

.leaflet-container {
  background: transparent !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  color: $black !important;
}

#liveMapContainer,
#routeStopsMapContainer {
  height: 100%;
  width: 100%;

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    background-color: $gray-900 !important;
    color: $black !important;
  }

  .circle {
    width: 7px;
    height: 7px;
    border-radius: 15px;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    z-index: 50;
    background-color: $primary;
    cursor: pointer;
    cursor: -o-pointer;
    cursor: -moz-pointer;
    cursor: -webkit-pointer;
  }

  .circle.big {
    width: 12px;
    height: 12px;
  }

  .circle.extra-big {
    width: 18px;
    height: 18px;
  }

  .circle.follow {
    background-color: $success !important;
  }

  .truck-label {
    position: absolute;
    top: -19px;
    color: black;
    width: max-content;
    left: -15px;
    background: transparentize(white, 0.3);
  }

  .city-name {
    font-weight: bold;
    size: 1rem;
  }
}

.tmpchat {
  .hightlight {
    color: yellow;
  }

  .system {
    color: #1e7ee9;
  }

  .pm {
    color: $teal;
  }

  .server {
    color: $danger;
  }

  .username {
    color: #cda56a;
  }
}

.image-gallery {
  width: 650px;
  margin: 0 auto;

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    color: $primary !important;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:focus,
  .image-gallery-thumbnail:hover {
    border-color: $primary !important;
  }
}

.project-video {
  margin: 0 auto;

  iframe {
    display: block;
    margin: 0 auto;
    width: 670px;
    height: 376px;
  }
}

.projects-list-item {
  &:hover {
    background-color: var(--bs-light);
  }

  h5 {
    border-bottom: none;
    padding-bottom: 0 !important;
    display: inline-block;
    margin-bottom: 0 !important;
  }
}

.mod-manager {
  aside {
    /*width: 440px;*/
    height: 100vh;

    .banner {
      position: absolute;
      bottom: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
      padding-right: 20px;
    }
  }

  .badge-lg {
    opacity: initial !important;
  }
}

.project-main-image {
  max-width: 690px;
}

.noMarginRight {
  margin-right: 0px !important;
}

.comments {
  .avatar {
    width: 50px;
  }

  h5,
  h6 {
    border-bottom: none;
  }
}

.rb-container {
  margin-left: 10px;
  display: block;
  position: relative;

  ul.rb {
    padding: 0;
    display: inline-block;
    margin-bottom: 0px !important;

    li {
      cursor: zoom-in;
      list-style: none;
      margin: auto;
      min-height: 50px;
      border-left: 1px dashed #fff;
      padding: 0 0 12px 30px;
      position: relative;

      &:last-child {
        border-left: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        min-height: 20px;
      }

      &::before {
        position: absolute;
        left: -11px;
        top: -5px;
        content: " ";
        border: 4px solid rgba(255, 255, 255, 1);
        border-radius: 500%;
        background: $warning;
        height: 20px;
        width: 20px;
        transition: all 500ms ease-in-out;
      }
    }
  }
}

.at-stop-animation {
  animation: blink-animation 1.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
  color: $warning !important;
}

.timeline-container {
  margin-left: 4px;
}

.timeline-label {
  &::before {
    left: 1px;
  }
}

@font-face {
  font-family: "RouteSign";
  src: url("fonts/advanced-led-board-7.regular.ttf") format("truetype");
}

.route-plate-container {
  background: black;
  @include gridBackground(0.3em, #272b30);

  .number {
    padding: 2px;
    display: inline-block;
    font-size: 20px;
    line-height: 50px;
    color: red;
    @include gridBackground(0.15em, #660000);
    font-family: "RouteSign";
  }

  .route {
    padding-right: 0;
    padding-left: 2px;
    font-size: 20px;
    line-height: 25px;
    color: #ffcc00;
    display: inline-block;
    font-family: "RouteSign";
  }
}

.row-table-header {
  background-color: $gray-900;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 2px;
}

.row-table-row {
  padding-left: 2px;
  cursor: zoom-in;
  padding-bottom: 5px;
  border-bottom: 1px solid $gray-700;

  .row-table-col {
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.fixed-banner {
  position: fixed;
  right: 5px;
  bottom: 5px;
  width: 400px;
  z-index: 999999;

  .no-ads-placeholder {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .ads {
    top: 35px;
    left: 0;
    position: absolute;
    z-index: 9999999;
  }
}

.ad-column-placeholder {
  width: 410px;
}

.desktop-window-component- {
  &profile {
    .ad-column-placeholder {
      width: 426px;
    }
  }

  &modManager {
    .ad-column-placeholder {
      width: 420px;
    }
  }
}

.go-premium {
  background-color: $card-bg;
  margin-bottom: 5px;
}

.card-95vh {
  min-height: 96vh;
}

.min-h-100 {
  min-height: 100% !important;
}

.small-text-button {
  font-size: 0.8rem;

  .svg-inline--fa {
    margin-right: 5px;
  }
}

/*.live-map {
  &.apply-custom-theme {
    header {
      background: transparent;
    }
  }
}*/

.brand {
  div {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 5px;
  }

  &.selected {
    border-bottom: 2px solid $primary;
  }
}

.model {
  div {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 5px;
  }

  &.selected {
    border-bottom: 2px solid $primary;
  }
}

.grid {
  display: grid;

  @for $i from 1 through 12 {
    &.cols-#{$i} {
      @include media-breakpoint-up(xs) {
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 10px;
      }

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat($i, 1fr);
      }

      column-gap: 10px;

      &.no-gap {
        column-gap: 0;
      }
    }
  }
}

/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  /* Set a specific height */
  min-height: 200px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Place text in the middle of the image */
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .buttons {
    position: absolute;
    top: 10px;
    right: 0px;
  }
}

.cards-h-no-style {
  h1,
  h2,
  h3,
  h5,
  h5,
  h6 {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}

.stats-list {
  .list-group-item {
    font-weight: 900;

    span.float-end {
      font-weight: 400;
    }
  }
}

.directory-company-card {
  cursor: pointer;

  &:hover {
    background-color: $card-bg;
  }
}

.enteprise-navbar {
  padding: 5px;

  .nav-item {
    margin-right: 10px;
  }
}

.company-leaderboard-position-badge {
  position: absolute;
  left: 10px;
}

/* bootstrap 5 */
.table {
  color: $body-color;

  > :not(:first-child) {
    border-top: none;
  }
}

.btn-info {
  color: $body-color;
}

.btn-danger {
  color: $body-color;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.form-text {
  display: block;
}

/* end bs5 */

.no-scroll {
  overflow: none;
}

/*._loading_overlay_wrapper,
._loading_overlay_overlay {
  height: calc(100vh - 50px);
}
*/

.registration-left {
  background-position: center;
  background-size: cover;
  box-shadow: 8px 3px 15px 2px rgba(0, 0, 0, 0.67);
}

.game-column {
  width: 33.33%;

  .background {
    width: 30%;
    background-position: 50%;
    background-size: cover;
  }
}

.even-columns {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  height: 97vh;

  .column {
    background-position: 50%;
    background-size: cover;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

input[type="color"].form-control {
  padding: 0 !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.form-range {
  width: 95% !important;
}

output {
  position: absolute;
  margin-left: 10px;
}

.user-activities {
  &.timeline-label:before {
    left: 92px;
  }

  &.timeline-label .timeline-label {
    width: 97px;

    &:before {
      left: 97px;
    }
  }
}

.leaflet-pane {
  z-index: 1 !important;
}

.flags img {
  width: 50px !important;
}

.achievement-requirements {
  margin-bottom: 0px;

  li {
    text-align: center;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.game-info-container {
  .game-info-component {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0.75rem !important;
    flex-shrink: 0;
    white-space: nowrap;
    flex-wrap: nowrap;

    &.rpm {
      max-width: 100px;
      width: 100px;
    }

    &.rpmbar {
      max-width: 200px;
      width: 200px;
    }

    &.job-info {
      margin-left: auto;
      margin-right: auto;

      div:not(:last-child) {
        margin-right: 0.75rem;
      }
    }

    &.speed {
      min-width: 200px;
      /*width: 200px;*/
    }

    &.fuel {
      min-width: 200px;
      /*width: 200px;*/
    }

    .navigation-component {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .game-info-component:first-child {
    padding-left: 0.75rem !important;
  }

  .game-info-component:last-child {
    padding-right: 0.75rem !important;
  }
}

.aside-enabled.aside-fixed.header-fixed .header {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.td-success {
  background-color: #17423f !important;
  box-shadow: none !important;
}

.td-danger {
  background-color: #522836 !important;
  box-shadow: none !important;
}

.job-events {
  overflow-y: auto;
  position: relative;
  height: 206px;

  .timeline {
    --timeline-color: #323248 !important;
    position: relative;
    list-style: none;
    position: absolute;
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;

    /* set here the height of the timeline */
    height: 175px;
  }

  /* middle line */
  .timeline:before {
    content: "";
    position: absolute;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background: var(--timeline-color);
  }

  .timeline li {
    border: 1px solid #2b2b40;
    background: var(--timeline-color);
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    border-radius: var(--bs-card-border-radius);
  }

  .timeline li:not(:last-child) {
    margin: 0 -50px 0 0;
  }

  /*  event in even position are bottom-aligned */
  .timeline li:nth-child(2n),
  .timeline li:nth-child(2n) div {
    align-self: flex-end;
  }

  .timeline div {
    position: relative;
    width: 200px;
    padding: 10px;
  }

  /* style for the dot over the timeline */
  .timeline .timeline-icon {
    position: absolute;
    top: 50%;
    margin-left: 100px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    //border: 1px solid white;
    width: 15px;
    height: 15px;
    padding: 3px;
    border-radius: 5px;
    background: $primary;
  }

  /* style for the event arrow */
  .timeline div:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 100%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -1px) rotateZ(45deg);
    background: var(--timeline-color);
  }

  /* position of the event arrow in odd position */
  .timeline li:nth-child(2n - 1) div:before {
    top: 100%;
    margin-top: -8px;
    border-right: 1px var(--timeline-color) solid;
    border-bottom: 1px var(--timeline-color) solid;
  }

  /* position of the event arrow in even position */
  .timeline li:nth-child(2n) div:before {
    top: 0;
    margin-top: -10px;
    border-left: 1px var(--timeline-color) solid;
    border-top: 1px var(--timeline-color) solid;
  }
}

.carousel-root {
  .slide {
    img {
      width: auto !important;
    }
  }
}

.event-description-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray-300;
  padding-top: 10px;
  padding-bottom: 10px;

  .label {
    font-weight: bold;
    width: 500px;
  }

  .value {
    flex-grow: 1;
  }
}

/* The hero image */
.event-hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  /* Set a specific height */
  min-height: 500px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  /* Place text in the middle of the image */
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .buttons {
    position: absolute;
    top: 10px;
    right: 0px;
  }
}