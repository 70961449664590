@import "common/sass/style.scss";

@mixin orientation($direction) {
  $orientation-landscape: "(orientation:landscape)";
  $orientation-portrait: "(orientation:portrait)";

  @if $direction == landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}

body {
  background-color: #151521 !important;
}

.app-container {
  height: 60px;
  background-color: $primary;

  a {
    color: $body-color;
  }
}

.content-container {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;

  @include media-breakpoint-up(xs) {
    margin-top: 55px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 90px;

    @include orientation(landscape) {
      margin-top: 55px;
    }
  }

  // ipad
  @media (min-width: 1024px) {
    margin-top: 90px;
  }

  @media (min-width: 1025px) {
    margin-top: 90px;
  }
}

.ad-column-placeholder {
  &:empty {
    display: none;
  }

  @include media-breakpoint-up(xs) {
    width: 100%;
    margin-top: 15px;
  }

  // ipad
  @media (min-width: 1024px) {
    width: 100%;
    margin-top: 15px;
  }

  @media (min-width: 1025px) {
    width: 336px;
    margin-top: 0px;
  }
}

.content-container {
  padding-top: 15px;

  /*@include media-breakpoint-up(xs) {
    padding-left: 5px;
    padding-right: 5px;
  }*/

  @include media-breakpoint-up(md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  // ipad
  @media (min-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 1025px) {
    //padding-left: 100px;
    //padding-right: 100px;
  }
}

footer {
  @include media-breakpoint-up(xs) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @include media-breakpoint-up(md) {
    padding-left: 15px;
    padding-right: 15px;
  }

  // ipad
  @media (min-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 1025px) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

body.inapp {
  footer {
    display: none;
  }

  .content-container {
    overflow-y: scroll;
  }

  .bottombar {
    display: flex;
  }

  &.livemap {
    .content-container {
      padding: 0;
    }
    .livemap-container {
      border: 0 !important;
    }
  }
}

.bottombar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  background-color: $secondary;
  width: 100%;
  display: none;
  padding: 10px 15px 10px 15px;
  box-shadow: 0 -2px 5px -2px #333;
  border-top: 1px solid $gray-600;
  font-size: 1.2rem;
  z-index: 999999;
}

#sidebar-sticky-stack {
  height: 100%;
}

#sidebar-wrapper {
  min-height: 100vh;
  width: 250px;
  margin: 0.25rem;

  .menu-title {
    &.active {
      font-weight: bold;
    }
  }
}
